import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "../src/pages/Login";
import Admin from "../src/pages/Admin/Admin";
import Asesor from "../src/pages/Asesor/Asesor";
import Patinador from "../src/pages/Patinador/Patinador";
import NotFound from "./components/NotFound";
import withAuth from "./middleware/withAuth";

// Wrap components with the HOC
const AdminWithAuth = withAuth(Admin, [1]);
const AsesorWithAuth = withAuth(Asesor, [2]);
const PatinadorWithAuth = withAuth(Patinador, [3]);

function App() {
  const userRole = localStorage.getItem('user_role');

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/admin/*"
          element={userRole === '1' ? <AdminWithAuth /> : <Navigate to="/404" />}
        />
        <Route
          path="/asesor/*"
          element={userRole === '2' ? <AsesorWithAuth /> : <Navigate to="/404" />}
        />
        <Route
          path="/patinador/*"
          element={userRole === '3' ? <PatinadorWithAuth /> : <Navigate to="/404" />}
        />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="*"
          element={userRole ? <Navigate to={`/${userRole === '1' ? 'admin' : userRole === '2' ? 'asesor' : 'patinador'}`} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
