import React from "react";
import "./DashboardAdmin.css";
import Cards from "./Cards/Cards";
import TableAdmin from "./Table/Table";

const DashboardAdmin = ({ userId }) => {
  return (
    <div className="DashboardAdmin">
      <h1 className="text-4xl font-bold text-white m-4 select-none">
        Dashboard
      </h1>
      <Cards />
      <TableAdmin userId={userId} />
    </div>
  );
};

export default DashboardAdmin;
