import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { Howl } from "howler";
import "./NotificationsAsesor.css";
import UpdatesAsesor from "./Updates/UpdatesAsesor";
import alertSound from "../../../assets/sound/alert.mp3";
import editSound from "../../../assets/sound/edit.mp3";

const socket = io(process.env.REACT_APP_HOST);

const NotificationsAsesor = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const notificationsRef = useRef(notifications);
  const soundRef = useRef(null);

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    soundRef.current = new Howl({ src: [alertSound] }); // Create Howl instance
    soundRef.edit = new Howl({ src: [editSound] });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const cachedUser = localStorage.getItem("user");
    const roleId = localStorage.getItem("user_role");
    const userId = localStorage.getItem("user_id");
    console.log("Received user ID from NotificationsAsesor:", userId);

    if (cachedUser) {
      setUser(JSON.parse(cachedUser));
      setLoading(false);
    }

    if (token) {
      fetch(`${process.env.REACT_APP_HOST}/api/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Unauthorized");
          }
          return response.json();
        })
        .then((data) => {
          setUser(data);
          localStorage.setItem("user", JSON.stringify(data));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
          setLoading(false);
        });

      fetchNotifications(roleId, userId, token); // Fetch notifications independently
    } else {
      setLoading(false);
    }

    socket.on("notification edit", (newNotification) => {
      console.log("Received new notification from server:", newNotification);
      if (soundRef.edit) {
        soundRef.edit.play(); // Play the sound
      }
      if (Array.isArray(newNotification)) {
        newNotification.forEach((notification) => {
          if (validateNotificationStructure(notification)) {
            setNotifications((prevNotifications) => [
              notification,
              ...prevNotifications,
            ]);
          } else {
            console.error("Invalid notification structure", notification);
          }
        });
      } else if (validateNotificationStructure(newNotification)) {
        setNotifications((prevNotifications) => [
          newNotification,
          ...prevNotifications,
        ]);
      } else {
        console.error("Invalid notification structure", newNotification);
      }
    });

    socket.on("notifications deleted", (deletedNotifications) => {
      console.log(
        "Received deleted notifications from server:",
        deletedNotifications
      );
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) =>
            !deletedNotifications.deletedIds.includes(notification.id)
        )
      );
    });

    // Clean up the effect
    return () => {
      socket.off("notification added");
      socket.off("notification edit");
      socket.off("notification finalizado");
      socket.off("notifications deleted");
    };
  }, []);

  const fetchNotifications = (roleId, userId, token) => {
    fetch(
      `${process.env.REACT_APP_HOST}/notifications-asesor?role_id=${roleId}&user_id=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setNotifications(data);
        notificationsRef.current = data;
      })
      .catch((error) => {
        console.error("Error fetching notifications: ", error);
      });
  };

  const validateNotificationStructure = (notification) => {
    const requiredFields = [
      "id",
      "user_id",
      "full_name",
      "notification",
      "created_at",
    ];
    for (const field of requiredFields) {
      if (
        !notification.hasOwnProperty(field) ||
        notification[field] === undefined ||
        notification[field] === null
      ) {
        console.error(
          `Notification is missing the field: ${field}`,
          notification
        );
        return false;
      }
    }
    return true;
  };

  if (loading) {
    return (
      <div className="loading">
        <p>Cargando...</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="error">
        <p>Error al cargar los datos del usuario.</p>
      </div>
    );
  }

  return (
    <div className="Notifications">
      <div className="user">
        <p>{user.full_name}</p>
        <span>{user.role_name}</span>
      </div>
      <div className="title mb-2">Notificaciones</div>
      <UpdatesAsesor notifications={notifications} />
    </div>
  );
};

export default NotificationsAsesor;
