import React, { useState } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import theme from '../styles/theme';
import logo from '../assets/img/Logo FU Domicilios.jpg';
import '../styles/Global.css';

function Login() {
    const [user_handle, setUserHandle] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    const [messageType, setMessageType] = useState('');
    // const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();

        if (!user_handle || !password) {
            setMessage('Por favor, complete ambos campos.');
            setVisible(true);
            return;
        }

        setLoading(true);
        axios.post(`${process.env.REACT_APP_HOST}/api/login`, { user_handle, password_hash_pin: password })
            .then(res => {
                console.log('Response data:', res.data);

                setMessage(res.data.message);
                setMessageType('success');
                setVisible(true);
                if (res.data.data) {
                    const { user_id, role_id, full_name } = res.data.data;
                    const token = res.data.token.split(' ')[1];

                    console.log('User ID:', user_id);
                    console.log('Role ID:', role_id);
                    console.log('Full Name:', full_name);
                    console.log('Token:', token);

                    localStorage.setItem('user_id', user_id);
                    localStorage.setItem('user_role', role_id);
                    localStorage.setItem('full_name', full_name);
                    localStorage.setItem('token', token);

                    console.log(`User ID stored in local storage: ${localStorage.getItem('user_id')}`);
                    console.log(`User role stored in local storage:', ${localStorage.getItem('user_role')}`);
                    console.log(`Full name stored in local storage: ${localStorage.getItem('full_name')}`);
                    console.log(`Token stored in local storage: ${localStorage.getItem('token')}`);
                    if (role_id === 1) {
                        console.log('Navigating to /admin');
                        window.location.href = '/admin';
                    } else if (role_id === 2) {
                        console.log('Navigating to /asesor');
                        window.location.href = '/asesor';
                    } else if (role_id === 3) {
                        console.log('Navigating to /patinador');
                        window.location.href = '/patinador';
                    }

                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        setMessage('El usuario no existe.');
                    } else if (err.response.status === 401) {
                        setMessage('La contraseña es incorrecta. Intente nuevamente.');
                    } else {
                        setMessage('Ocurrió un error durante el inicio de sesión. Inténtelo de nuevo.');
                    }
                } else {
                    setMessage('Ocurrió un error en el servidor. Inténtelo de nuevo más tarde.');
                }
                setMessageType('error');
                setVisible(true);
                console.log(err);
            })
            .finally(() => setLoading(false));
    }

    function handleInputChange(setter) {
        return (e) => {
            setter(e.target.value);
            if (message) {
                setVisible(false);
                setTimeout(() => setMessage(''), 300);
            }
        };
    }

    return (
        <div className="flex justify-center items-center min-h-screen" style={{ background: 'linear-gradient(106.37deg, #111 0%, #2b2b2b 15%, #444 30%, #5c8e2a 60%, #7DB73A 80%)' }}>
            <div className="glass backdrop-blur-md p-8 rounded-lg shadow-lg w-full max-w-md mx-4 border border-gray-200">
                <div className="flex justify-center mb-4">
                    <img src={logo} alt="Logo" className="w-24 h-24 rounded-full logo-main" />
                </div>
                <h1 className="text-2xl font-bold text-center mb-2" style={{ color: theme.colors.textPrimary }}>Iniciar Sesión</h1>
                <p className='text-center mb-2' style={{ color: theme.colors.textSecondary }}>
                    <strong>Finca Urbana <span className='neon-text'>Domicilios</span></strong>
                </p>
                <p className="text-center mb-6 font-semibold italic text-[#f8d37e]">Manejador de Toma Pedidos</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="user_handle" className="block text-sm font-bold mb-2" style={{ color: theme.colors.textPrimary }}>Nombre de usuario</label>
                        <input
                            id='user_handle'
                            type="text"
                            placeholder="Nombre de usuario"
                            className="w-full p-3 rounded border border-gray-300 focus:ring-2 focus:ring-[#7DB73A] focus:outline-none"
                            onChange={handleInputChange(setUserHandle)}
                            value={user_handle}
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-sm font-bold mb-2" style={{ color: theme.colors.textPrimary }}>Contraseña</label>
                        <input
                            id='password'
                            type="password"
                            placeholder="Contraseña"
                            className="w-full p-3 rounded border border-gray-300 focus:ring-2 focus:ring-[#7DB73A] focus:outline-none"
                            onChange={handleInputChange(setPassword)}
                            value={password}
                        />
                    </div>
                    <button type="submit"
                        className="w-full py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#7DB73A] focus:ring-opacity-50 shadow-xl"
                        style={{
                            background: 'linear-gradient(106.37deg, #333 0%, #444 40%, #7DB73A 100%)',
                            backgroundColor: theme.colors.secondary
                        }} disabled={loading}>
                        {loading ? 'Cargando...' : 'Iniciar Sesión'}
                    </button>
                </form>
                {message && (
                    <div className={`mt-6 mx-auto p-4 ${messageType === 'success' ? 'bg-green-500' : 'bg-red-500'} bg-opacity-40 backdrop-blur-md text-white text-center font-bold rounded-md shadow-lg border border-white border-opacity-20 animate-shake ${visible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
                        <p>{message}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
