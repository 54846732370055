import React, { useState, useRef } from "react";
import { Stage, Layer, Image, Line } from "react-konva";
import useImage from "use-image";
import axios from "axios";

const KonvaCanvas = ({ imageUrl, orderId, onClose }) => {
  const [image] = useImage(imageUrl, "anonymous");
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [history, setHistory] = useState([]); // Stack to keep track of history
  const stageRef = useRef(null); // Reference to the stage
  const [statusChanged, setStatusChanged] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  // Retrieve user information from local storage
  const userId = localStorage.getItem("user_id");
  const userFullName = localStorage.getItem("full_name");

  const handleStartDrawing = (event) => {
    setIsDrawing(true);
    // Save the current state to history before starting a new line
    setHistory((prevHistory) => [...prevHistory, lines]);
    setLines([...lines, { points: [] }]);
  };

  const handleDrawing = (event) => {
    if (!isDrawing) return;

    const stage = event.target.getStage();
    const point = stage.getPointerPosition();
    const lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // Replace the last line with the updated one
    setLines([...lines.slice(0, -1), lastLine]);
  };

  const handleStopDrawing = () => {
    setIsDrawing(false);
  };

  const undoLastLine = () => {
    if (history.length === 0) return;
    const lastHistory = history[history.length - 1];
    setHistory(history.slice(0, -1));
    setLines(lastHistory);
  };

  const saveCanvas = async () => {
    const stage = stageRef.current;
    const dataURL = stage.toDataURL();
    console.log("Canvas data URL:", dataURL);

    setLoading(true); // Start loading

    if (!statusChanged) {
      try {
        await axios.put(
          `${process.env.REACT_APP_HOST}/api/orders-taking/${orderId}`,
          {
            order_status: "Revisando",
            user_id: userId,
            user_full_name: userFullName,
          }
        );
        setStatusChanged(true);
        console.log("Order status updated to Revisando");
      } catch (error) {
        console.error("Error updating order status: ", error);
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/save-image`,
        {
          image: dataURL,
          orderId,
          user_id: userId,
          user_full_name: userFullName,
        }
      );
      console.log("Image saved successfully:", response.data);

      window.location.reload();
    } catch (error) {
      console.error("Error saving image:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div>
      <Stage
        width={750}
        height={400}
        onMouseDown={handleStartDrawing}
        onMouseMove={handleDrawing}
        onMouseUp={handleStopDrawing}
        onTouchStart={handleStartDrawing} // Touch event handlers
        onTouchMove={handleDrawing} // Touch event handlers
        onTouchEnd={handleStopDrawing} // Touch event handlers
        ref={stageRef} // Attach the ref to the stage
      >
        <Layer>
          <Image
            image={image}
            width={750}
            height={400}
            crossOrigin="anonymous"
          />
        </Layer>
        <Layer>
          {lines.map((line, index) => (
            <Line
              key={index}
              points={line.points}
              stroke="red"
              strokeWidth={5}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation="source-over"
            />
          ))}
        </Layer>
      </Stage>
      <div className="flex justify-center mt-2 gap-4">
        <button
          className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#3498DB] focus:ring-opacity-50"
          style={{ background: "#3498DB" }}
          onClick={undoLastLine}
          disabled={loading} // Disable button when loading
        >
          {loading ? "Cargando..." : "Deshacer"}
        </button>
        <button
          style={{
            background: "#7DB73A",
          }}
          className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#7DB73A] focus:ring-opacity-50"
          onClick={saveCanvas}
          disabled={loading} // Disable button when loading
        >
          {loading ? "Cargando..." : "Guardar"}
        </button>
        <button
          className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#E74C3C] focus:ring-opacity-50"
          style={{ background: "#E74C3C" }}
          onClick={onClose}
          disabled={loading} // Disable button when loading
        >
          {loading ? "Cargando..." : "Cancelar"}
        </button>
      </div>
    </div>
  );
};

export default KonvaCanvas;
