import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/img/Logo FU Domicilios.jpg";
import "./SidebarAsesor.css";
import { SidebarDataAsesor } from "../../../data/Asesor/DataAsesor";
import { UilBars } from "@iconscout/react-unicons";

import { motion } from "framer-motion";

const SidebarAsesor = () => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user_role");
    navigate("/login");
  };

  const handleNavigation = (index, link) => {
    setSelected(index);
    navigate(link);
  };

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpanded(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className="Sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* Logo */}
        <div className="logo">
          <img src={logo} alt="Logo FUD" />
        </div>
        <span className="Eslogan">
          Finca Urbana <span>Domicilios</span>
        </span>
        {/* Menu items */}
        <div className="menu">
          {SidebarDataAsesor.map((item, index) => {
            if (item.heading === "Salir") {
              return (
                <div key={index} className="menuItem" onClick={handleLogout}>
                  <item.icon />
                  <span>{item.heading}</span>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className={
                    selected === index ? "menuItem active" : "menuItem"
                  }
                  onClick={() => handleNavigation(index, item.link)}
                >
                  <item.icon />
                  <span>{item.heading}</span>
                </div>
              );
            }
          })}
        </div>
      </motion.div>
    </>
  );
};

export default SidebarAsesor;
