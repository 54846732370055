import React, { useEffect, useState } from "react";
import "./UpdatesAsesor.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UpdatesAsesor = ({ notifications = [] }) => {
  console.log("Received notifications from UpdatesAsesor.jsx:", notifications);
  const navigate = useNavigate();
  const [time, setTime] = useState(Date.now());
  const [readNotifications, setReadNotifications] = useState(() => {
    const saved = localStorage.getItem("readNotifications");
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    const firstInterval = setInterval(() => {
      setTime(Date.now());
    }, 1000); // Update every second

    const secondInterval = setTimeout(() => {
      clearInterval(firstInterval);
      setInterval(() => {
        setTime(Date.now());
      }, 60000); // Update every minute
    }, 60000); // Switch to updating every minute after one minute

    return () => {
      clearInterval(firstInterval);
      clearTimeout(secondInterval);
    }; // Cleanup intervals on component unmount
  }, []);

  const formatTimeAgo = (time) => {
    const now = moment();
    const createdTime = moment(time);
    const diffInSeconds = now.diff(createdTime, "seconds");

    if (diffInSeconds < 60) {
      return `${diffInSeconds} segundos`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = now.diff(createdTime, "minutes");
      return `${diffInMinutes} minutos`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = now.diff(createdTime, "hours");
      return `${diffInHours} horas`;
    } else {
      const diffInDays = now.diff(createdTime, "days");
      return `${diffInDays} días`;
    }
  };

  const handleNotificationsClick = (id, message) => {
    setReadNotifications((prev) => {
      const updated = { ...prev, [id]: true };
      localStorage.setItem("readNotifications", JSON.stringify(updated));
      return updated;
    });
    if (message) {
      const orderIdMatch = message.match(/ID #(\d+)/);
      if (orderIdMatch) {
        const orderId = orderIdMatch[1];
        navigate(`/asesor/mis-tp/order/${orderId}`);
      } else {
        console.error("order_id not found in message:", message);
      }
    } else {
      console.error("Message is undefined:", message);
    }
  };

  return (
    <div className="Updates" style={{ maxHeight: "calc(100vh - 200px)" }}>
      {notifications.length === 0 ? (
        <div className="no-notifications">No hay notificaciones</div>
      ) : (
        notifications.map((update, index) => (
          <div
            className="update"
            key={index}
            onClick={() =>
              handleNotificationsClick(update.id, update.notification)
            }
            style={{ cursor: "pointer", position: "relative" }}
          >
            {!readNotifications[update.id] && (
              <div className="unread-indicator"></div>
            )}
            <div className="notification">
              <div className="notification-header">
                <span className="notification-name">{update.full_name}</span>
                <span className="notification-action">
                  {update.notification}
                </span>
              </div>
              <span className="notification-time">
                {" hace "}
                {formatTimeAgo(update.created_at)}
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UpdatesAsesor;
