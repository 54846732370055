import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "./UsersAdmin.css";

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [formData, setFormData] = useState({
    full_name: "",
    user_handle: "",
    password_hash_pin: "",
    role_id: 1,
  });
  const [error, setError] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState(null);

  // Get logged-in user's ID from token
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInUserId(decodedToken.id);
    }
  }, []);

  // Get all users - Read
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/api/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Fetched users:", response.data);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Edit a user - Update
  const handleEdit = (user) => {
    setIsEditing(true);
    setEditUserId(user.id);
    setFormData({
      full_name: user.full_name,
      user_handle: user.user_handle,
      password_hash_pin: "",
      role_id: user.role_id,
    });
    setShowModal(true);
  };

  // Delete a user - Delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_HOST}/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUsers(users.filter((user) => user.id !== id));
      console.log(`User with ID ${id} deleted successfully`);
    } catch (error) {
      console.error(`Error deleting user with ID ${id}:`, error);
    }
  };

  // Create a new user - Create
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!/^\d{6}$/.test(formData.password_hash_pin)) {
      setError(
        "La contraseña debe ser numérica y tener exactamente 6 dígitos."
      );
      return;
    }

    try {
      if (isEditing) {
        await axios.put(
          `${process.env.REACT_APP_HOST}/api/users/${editUserId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(`User with ID ${editUserId} updated successfully`);
      } else {
        await axios.post(`${process.env.REACT_APP_HOST}/api/create`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log("User created successfully");
      }
      setFormData({
        full_name: "",
        user_handle: "",
        password_hash_pin: "",
        role_id: 1,
      });
      setShowModal(false);
      setIsEditing(false);
      fetchUsers();
    } catch (e) {
      console.error("Error creating user:", e.response ? e.response.data : e);
      setError(
        e.response ? e.response.data.message : "Error creando el usuario."
      );
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mt-8 px-16">
      <h1 className="text-4xl text-white mb-8">Usuarios</h1>
      <button
        className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded mb-4 self-end"
        onClick={() => {
          setShowModal(true);
          setIsEditing(false);
          setFormData({
            full_name: "",
            user_handle: "",
            password_hash_pin: "",
            role_id: 1,
          });
        }}
      >
        Crear
      </button>
      <div className="table-container">
        <table className="table-auto w-full bg-white shadow-lg rounded-lg">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="px-2 py-2 text-center w-1/4">
                Nombre del usuario
              </th>
              <th className="px-2 py-2 text-center w-1/4">Rol</th>
              <th className="px-2 py-2 text-center w-1/4">Opciones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="border-t">
                <td className="border px-1 py-2 text-center">
                  {user.full_name}
                </td>
                <td className="border px-1 py-2 text-center">
                  {user.role_name}
                </td>
                <td className="border px-1 py-2 text-center">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                    onClick={() => handleEdit(user)}
                  >
                    Editar
                  </button>
                  {user.id !== loggedInUserId && (
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded ml-2"
                      onClick={() => handleDelete(user.id)}
                    >
                      Eliminar
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal ? (
        <>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 shadow-lg w-1/3">
              <h2 className="text-2xl mb-4">
                {isEditing ? "Editar Usuario" : "Crear Nuevo Usuario"}
              </h2>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="full_name"
                  >
                    Nombre Completo
                  </label>
                  <input
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="user_handle"
                  >
                    Usuario
                  </label>
                  <input
                    type="text"
                    name="user_handle"
                    value={formData.user_handle}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password_hash_pin"
                  >
                    Contraseña
                  </label>
                  <input
                    type="password"
                    name="password_hash_pin"
                    value={formData.password_hash_pin}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="role_id"
                  >
                    Rol
                  </label>
                  <select
                    name="role_id"
                    value={formData.role_id}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="">Seleccione un rol</option>
                    <option value={1}>Administrador</option>
                    <option value={2}>Asesor</option>
                    <option value={3}>Patinador</option>
                  </select>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {isEditing ? "Actualizar" : "Crear"}
                  </button>
                  <button
                    type="button"
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
        </>
      ) : null}
    </div>
  );
};

export default UsersAdmin;
