import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePickerComponent.css";

const DateRangePickerComponent = ({ onDatesChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    onDatesChange({ startDate, endDate });
  }, [startDate, endDate, onDatesChange]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onDatesChange({ startDate: date, endDate });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onDatesChange({ startDate, endDate: date });
  };

  return (
    <div className="date-range-picker">
      <div className="date-picker-wrapper">
        <div className="date-picker-wrapper-label">
          <label className="date-picker-label">Fecha de inicio</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Fecha de inicio"
            className="date-picker-input"
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="date-picker-wrapper">
          <div className="date-picker-wrapper-label">
            <label className="date-picker-label">Fecha final</label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="Fecha final"
              className="date-picker-input"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePickerComponent;
