const theme = {
    colors: {
        primary: '#111',
        secondary: '#7DB73A',
        textPrimary: '#fff',
        textSecondary: '#fff',
        white: '#fff',
        black: '#000',
        error: '#f00',
    },
};

export default theme;