import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./Chat.css";

const Chat = ({ userId, username }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(`${process.env.REACT_APP_HOST}`, {
      auth: {
        username: username,
        userId: userId,
        serverOffset: 0,
      },
    });

    socket.current.on(
      "chat message",
      (msg, serverOffset, username, createdAt) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { msg, serverOffset, username, createdAt },
        ]);
        socket.current.auth.serverOffset = serverOffset;
      }
    );

    return () => {
      socket.current.disconnect();
    };
  }, [userId, username]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.trim()) {
      socket.current.emit("chat message", input);
      setInput("");
    }
  };

  return (
    <div className="container">
      <div className="app-chat">
        <div className="screen chat-screen">
          <div className="space"></div>
          <div className="header">
            <div className="logo">Chatroom</div>
          </div>
          <div className="messages">
            {messages
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.username === username
                      ? "my-message"
                      : "other-message"
                  }`}
                >
                  <div>
                    <div className="name">{message.username}</div>
                    <div className="text">{message.msg}</div>
                    <div className="timestamp">
                      {new Date(message.createdAt).toLocaleString()}
                    </div>
                  </div>
                </div>
              ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="typebox">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Escribe un mensaje..."
              />
              <button type="submit">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
