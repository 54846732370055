import React from "react";
import "./Admin.css";
import SidebarAdmin from "../../components/Admin/Sidebar/SidebarAdmin";
import DashboardAdmin from "../../components/Admin/Content/Dashboard/DashboardAdmin";
import UsersAdmin from "../../components/Admin/Content/Users/UsersAdmin";
import ChatAdmin from "../../components/Admin/Content/Chat/ChatAdmin";
import HistoryAdmin from "../../components/Admin/Content/History/HistoryAdmin";

import { Routes, Route, Navigate } from 'react-router-dom';
import NotificationsAdmin from "../../components/Admin/Notifications/NotificationsAdmin";

function Admin() {
    const userId = localStorage.getItem('user_id');
    const fullName = localStorage.getItem('full_name');

    return (
        <div className="App">
            <div className="AppGlass">
                <SidebarAdmin />
                <div className="content">
                    <Routes>
                        <Route path="dashboard" element={<DashboardAdmin userId={userId} />} />
                        <Route path="users" element={<UsersAdmin />} />
                        <Route path="chat" element={<ChatAdmin userId={userId} username={fullName} />} />
                        <Route path="history" element={<HistoryAdmin userId={userId} />} />
                        <Route path="*" element={<Navigate to="dashboard" />} />
                    </Routes>
                </div>
                <NotificationsAdmin />
            </div>
        </div>
    )
}

export default Admin;