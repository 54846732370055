import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import axios from "axios";
import theme from "../../../../../styles/theme";
// import ChatOrder from "../../../../Utils/ChatOrder/ChatOrder";
import Modal from "../../../../Utils/Modal/Modal";
import ModalCanvas from "../../../../Utils/CanvasModal/ModalCanvas";
import "./OrderDetail.css";

const OrderDetail = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST}/orders/${orderId}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [orderId]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_HOST}`);

    socket.on("order status updated", (updatedOrder) => {
      if (updatedOrder.id === parseInt(orderId, 10)) {
        console.log("Updated order data: ", updatedOrder);
        const updatedOrderWithCorrectURL = {
          ...updatedOrder,
          file_url: updatedOrder.file_url,
        };
        console.log(
          "Constructed file_url from socket: ",
          updatedOrderWithCorrectURL.file_url
        );

        setOrder(updatedOrderWithCorrectURL);
      }
    });

    return () => socket.disconnect();
  }, [orderId]);

  const makeStyles = (order_status) => {
    if (order_status === "Finalizado") {
      return {
        background: "rgb(145 254 159 / 47%)",
        color: "green",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    } else if (order_status === "Pendiente") {
      return {
        color: "red",
        background: "rgb(254 145 159 / 47%)",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    } else if (order_status === "Revisando") {
      return {
        color: "blue",
        background: "#adc6ff94",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    }
  };

  const formatDateTime = (isoString) => {
    if (!isoString) {
      return "No Confirmado";
    }
    const date = new Date(isoString);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    return `${date.toLocaleDateString(
      "es-ES",
      options
    )}, ${date.toLocaleTimeString("es-ES", timeOptions)}`;
  };

  const openModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageUrl("");
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const handleEditOrder = () => {
    openCanvasModal();
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-order-detail-asesor text-white items-center justify-center flex flex-col p-8">
      <div className="content-center flex flex-col items-center justify-center text-center">
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-col mr-8 items-center">
            <button onClick={() => navigate(-1)} className="back-button">
              Volver
            </button>
            <img
              className="rounded-xl cursor-pointer"
              src={`${process.env.REACT_APP_HOST}/${order.file_url}`}
              alt="Toma Pedido"
              style={{ width: "550px", height: "270px" }}
              onClick={() =>
                openModal(`${process.env.REACT_APP_HOST}/${order.file_url}`)
              }
            />
            <button
              onClick={handleEditOrder}
              type="submit"
              className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#7DB73A] focus:ring-opacity-50"
              style={{
                background: "#7DB73A",
                backgroundColor:
                  order.order_status === "Finalizado"
                    ? "#ccc"
                    : theme.colors.secondary,
              }}
              disabled={loading || order.order_status === "Finalizado"}
            >
              {loading ? "Cargando..." : "Editar Toma Pedido"}
            </button>
          </div>

          <div className="mt-6 font-bold text-start">
            <p>ID: {order.id}</p>
            <p className="mt-3">
              Asesor Responsable:{" "}
              <span className="font-semibold">{order.user_full_name}</span>
            </p>
            <p className="mt-3">
              Fecha de creación:{" "}
              <span className="font-semibold">
                {formatDateTime(order.created_at) || "N/A"}
              </span>
            </p>
            <p className="mt-3">
              Estado:{" "}
              <span
                style={makeStyles(order.order_status)}
                className="font-semibold"
              >
                {order.order_status}
              </span>
            </p>
            <p className="mt-3">
              Confirmación:{" "}
              <span className="font-semibold">
                {formatDateTime(order.confirmation_date) || "N/A"}
              </span>
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        imageUrl={currentImageUrl}
      />

      <ModalCanvas
        open={isCanvasModalOpen}
        onClose={closeCanvasModal}
        imageUrl={`${process.env.REACT_APP_HOST}/${order.file_url}`}
        orderId={orderId}
      />
    </div>
  );
};

export default OrderDetail;
