import React, { useEffect, useState } from "react";
import "./Updates.css";
import moment from "moment";

const Updates = ({ notifications = [] }) => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const firstInterval = setInterval(() => {
      setTime(Date.now());
    }, 1000); // Update every second

    const secondInterval = setTimeout(() => {
      clearInterval(firstInterval);
      setInterval(() => {
        setTime(Date.now());
      }, 60000); // Update every minute
    }, 60000); // Switch to updating every minute after one minute

    return () => {
      clearInterval(firstInterval);
      clearTimeout(secondInterval);
    }; // Cleanup intervals on component unmount
  }, []);

  const formatTimeAgo = (time) => {
    const now = moment();
    const createdTime = moment(time);
    const diffInSeconds = now.diff(createdTime, "seconds");

    if (diffInSeconds < 60) {
      return `${diffInSeconds} segundos`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = now.diff(createdTime, "minutes");
      return `${diffInMinutes} minutos`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = now.diff(createdTime, "hours");
      return `${diffInHours} horas`;
    } else {
      const diffInDays = now.diff(createdTime, "days");
      return `${diffInDays} días`;
    }
  };

  return (
    <div className="Updates" style={{ maxHeight: "calc(100vh - 200px)" }}>
      {notifications.length === 0 ? (
        <div className="no-notifications">No hay notificaciones</div>
      ) : (
        notifications.map((update, index) => (
          <div className="update" key={index} style={{ userSelect: "none" }}>
            <div className="notification">
              <div className="notification-header">
                <span className="notification-name">{update.full_name}</span>
                <span className="notification-action">
                  {update.notification}
                </span>
              </div>
              <span className="notification-time">
                {" hace "}
                {formatTimeAgo(update.created_at)}
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Updates;
