import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import SidebarPatinador from "../../components/Patinador/Sidebar/SidebarPatinador";
import AllTP from "../../components/Patinador/Content/AllTP/AllTP";
import ChatPatinador from "../../components/Patinador/Content/Chat/ChatPatinador";
import HistoryPatinador from "../../components/Patinador/Content/Historial/HistoryPatinador";
import NotificationsPatinador from "../../components/Patinador/Notifications/NotificationsPatinador";
import OrderDetail from "../../components/Patinador/Content/AllTP/OrderDetail/OrderDetail";

function Patinador() {
    const userId = localStorage.getItem('user_id');
    const fullName = localStorage.getItem('full_name');

    return (
        <div className="App">
            <div className="AppGlass">
                <SidebarPatinador />
                <div className="content">
                    <Routes>
                        <Route path="all-tp" element={<AllTP userId={userId} />} />
                        <Route path="chat" element={<ChatPatinador userId={userId} username={fullName} />} />
                        <Route path="history" element={<HistoryPatinador userId={userId} />} />
                        <Route path="all-tp/order/:orderId" element={<OrderDetail />} />
                        <Route path="*" element={<Navigate to="all-tp" />} />
                    </Routes>
                </div>
                <NotificationsPatinador />
            </div>
        </div>
    )
}

export default Patinador;