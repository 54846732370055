// Sidebar imports
import {
    UilClipboardAlt,
    UilCommentLines,
    UilHistory,
    UilSignout,
} from '@iconscout/react-unicons';

// Sidebar Data
export const SidebarDataPatinador = [
    {
        icon: UilClipboardAlt,
        heading: 'Toma Pedidos',
        link: '/patinador/all-tp'
    },
    {
        icon: UilCommentLines,
        heading: 'Chat',
        link: '/patinador/chat'
    },
    {
        icon: UilHistory,
        heading: 'Historial',
        link: '/patinador/history'
    },
    {
        icon: UilSignout,
        heading: 'Salir',
        link: '/login'
    }
];

// Recent Updates Card Data
export const UpdatesDataPatinador = [

    {
        name: 'Sebastian Puerta',
        notification: ' a creado un toma pedido con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Santiago Gonzalez',
        notification: ' a confirmado un toma pedido con ID #12145',
        time: 'hace 10 minutos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
]