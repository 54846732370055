import React from "react";
import './Asesor.css';
import SidebarAsesor from "../../components/Asesor/Sidebar/SidebarAsesor";
import MisTP from "../../components/Asesor/Content/MisTP/MisTP";
import SubirTP from "../../components/Asesor/Content/SubirTP/SubirTP";
import ChatAsesor from "../../components/Asesor/Content/Chat/ChatAsesor";
import HistoryAsesor from "../../components/Asesor/Content/History/HistoryAsesor";

import { Routes, Route, Navigate } from 'react-router-dom';
import NotificationsAsesor from "../../components/Asesor/Notifications/NotificationsAsesor";
import OrderDetailAsesor from "../../components/Asesor/Content/MisTP/OrderDetailAsesor/OrderDetailAsesor";

function Asesor() {
    const userId = localStorage.getItem('user_id');
    const fullName = localStorage.getItem('full_name');

    return (
        <div className="App">
            <div className="AppGlass">
                <SidebarAsesor />

                <div className="content">
                    <Routes>
                        <Route path="mis-tp" element={<MisTP userId={userId} />} />
                        <Route path="subir-tp" element={<SubirTP userId={userId} />} />
                        <Route path="chat" element={<ChatAsesor userId={userId} username={fullName} />} />
                        <Route path="history" element={<HistoryAsesor userId={userId} />} />
                        <Route path="mis-tp/order/:orderId" element={<OrderDetailAsesor />} />
                        <Route path="*" element={<Navigate to="mis-tp" />} />
                    </Routes>
                </div>
                <NotificationsAsesor />
            </div>
        </div>
    )
}

export default Asesor;