import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./HistoryTablePatinador.css";
import Modal from "../../../../Utils/Modal/Modal";
import chatIcon from "../../../../../assets/img/chat.png";
import DateRangePickerComponent from "../../../../Utils/DateRangePickerComponent";

const HistoryTablePatinador = ({ userId }) => {
  console.log("Received user ID in HistoryTablePatinador:", userId);

  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [currentDeletionTime, setCurrentDeletionTime] = useState(60);

  const navigate = useNavigate();

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_HOST}`);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST}/images`
        );
        const filteredRows = response.data
          .filter((row) => row.order_status === "Finalizado")
          .map((row) => ({
            ...row,
            file_url: `${process.env.REACT_APP_HOST}/${row.file_url}`,
          }));
        setRows(filteredRows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    socket.on("image uploaded", (newImages) => {
      const filteredRows = newImages
        .filter((image) => image.order_status === "Finalizado")
        .map((image) => ({
          ...image,
          file_url: `${process.env.REACT_APP_HOST}/${image.file_url}`,
        }));
      setRows((prevRows) => [...prevRows, ...filteredRows]);
    });

    socket.on("order status updated", (updatedOrder) => {
      console.log("Evento recibido: order status updated", updatedOrder);
      setRows((prevRows) => {
        let updatedRows = prevRows.map((row) =>
          row.id === updatedOrder.id
            ? {
                ...updatedOrder,
                file_url: `${process.env.REACT_APP_HOST}/${updatedOrder.file_url}`,
              }
            : row
        );

        if (!updatedRows.some((row) => row.id === updatedOrder.id)) {
          updatedRows = [
            ...updatedRows,
            {
              ...updatedOrder,
              file_url: `${process.env.REACT_APP_HOST}/${updatedOrder.file_url}`,
            },
          ];
        }

        console.log("Rows after update:", updatedRows);
        return updatedRows.filter((row) => row.order_status === "Finalizado");
      });
    });

    socket.on("order deleted", (deletedOrder) => {
      console.log("Evento recibido: order deleted", deletedOrder);
      setRows((prevRows) =>
        prevRows.filter((row) => row.id !== deletedOrder.id)
      );
    });

    socket.on("deletion time updated", (deletionTime) => {
      setCurrentDeletionTime(deletionTime);
    });

    return () => {
      socket.off("image uploaded");
      socket.off("order status updated");
      socket.off("order deleted");
      socket.off("deletion time updated");
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST}/api/deletion-time`)
      .then((response) => {
        const deletionTime = response.data.deletionTime / 60;
        setCurrentDeletionTime(deletionTime);
      });
  }, []);

  const makeStyles = (order_status) => {
    if (order_status === "Finalizado") {
      return {
        background: "rgb(145 254 159 / 47%)",
        color: "green",
      };
    } else if (order_status === "Pendiente") {
      return {
        background: "#ffadad8f",
        color: "red",
      };
    } else if (order_status === "Revisando") {
      return {
        color: "blue",
        background: "#adc6ff94",
      };
    }
  };

  const openModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageUrl("");
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    return `${date.toLocaleDateString(
      "es-ES",
      options
    )}, ${date.toLocaleTimeString("es-ES", timeOptions)}`;
  };

  const openOrderDetail = (orderId) => {
    navigate(`/patinador/all-tp/order/${orderId}`);
  };

  const handleDatesChange = useCallback(({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  }, []);

  const filterRowsByDate = (rows, { startDate, endDate }) => {
    if (!startDate || !endDate) return rows;
    return rows.filter((row) => {
      const confirmationDate = new Date(row.confirmation_date);
      const normalizedStartDate = new Date(startDate).setHours(0, 0, 0, 0);
      const normalizedEndDate = new Date(endDate).setHours(23, 59, 59, 999);
      return (
        confirmationDate >= normalizedStartDate &&
        confirmationDate <= normalizedEndDate
      );
    });
  };

  const filteredRows = filterRowsByDate(rows, dateRange);

  return (
    <div className="Table">
      <DateRangePickerComponent onDatesChange={handleDatesChange} />
      <p className="text-white mb-2 font-bold" style={{ fontSize: "13px" }}>
        Tiempo actual de eliminación: {currentDeletionTime} minutos
      </p>
      {filteredRows.length === 0 ? (
        <p className="align-center text-[#ff0000] font-bold text-2xl">
          No hay toma de pedidos
        </p>
      ) : (
        <TableContainer
          component={Paper}
          className="table-container"
          style={{
            boxShadow: "0px 13px 20px 0px #80808029",
            maxHeight: "calc(100vh - 300px)",
          }}
        >
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Toma Pedido</TableCell>
                <TableCell align="center">ID del Toma Pedido</TableCell>
                <TableCell align="center">Asesor Responsable</TableCell>
                <TableCell align="center">Fecha De Creación</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    <img
                      src={row.file_url}
                      alt="Preview"
                      style={{
                        width: "100px",
                        height: "65px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => openModal(row.file_url)}
                    />
                  </TableCell>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.user_full_name}</TableCell>
                  <TableCell align="center">
                    {formatDateTime(row.created_at)}
                  </TableCell>
                  <TableCell align="center">
                    <span
                      className="status"
                      style={makeStyles(row.order_status)}
                    >
                      {row.order_status}
                    </span>
                  </TableCell>
                  <TableCell align="center" className="Details">
                    <button onClick={() => openOrderDetail(row.id)}>
                      <img
                        src={chatIcon}
                        alt="Chat"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        imageUrl={currentImageUrl}
      />
    </div>
  );
};

export default HistoryTablePatinador;
