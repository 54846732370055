// Sidebar imports
import {
    UilClipboardAlt,
    UilClipboard,
    UilCommentLines,
    UilHistory,
    UilSignout,
} from '@iconscout/react-unicons';

// Sidebar Data
export const SidebarDataAsesor = [

    {
        icon: UilClipboardAlt,
        heading: 'Mis TP',
        link: '/asesor/mis-tp'
    },
    {
        icon: UilClipboard,
        heading: 'Subir TP',
        link: '/asesor/subir-tp'
    },
    {
        icon: UilCommentLines,
        heading: 'Chat',
        link: '/asesor/chat'
    },
    {
        icon: UilHistory,
        heading: 'Historial',
        link: '/asesor/history'
    },
    {
        icon: UilSignout,
        heading: 'Salir',
        link: '/login'
    }
];

// Recent Updates Card Data
export const UpdatesDataAsesor = [

    {
        name: 'Sebastian Puerta',
        notification: ' a creado un toma pedido con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Santiago Gonzalez',
        notification: ' a confirmado un toma pedido con ID #12145',
        time: 'hace 10 minutos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
]