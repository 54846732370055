import React from "react";
import TablePatinador from "./Table/TablePatinador";

const AllTP = ({ userId }) => {
  console.log("Received user ID in AllTP:", userId);

  return (
    <div className="justify-center flex flex-col items-center mt-16">
      <div
        className="text-white text-center font-bold text-2xl mb-8"
        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        Toma Pedidos
      </div>

      <TablePatinador userId={userId} />
    </div>
  );
};

export default AllTP;
