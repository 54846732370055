import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { Howl } from "howler";
import "./NotificationsPatinador.css";
import UpdatesPatinador from "./Updates/UpdatesPatinador";
import alertSound from "../../../assets/sound/alert.mp3";
import editSound from "../../../assets/sound/edit.mp3";

const socket = io(process.env.REACT_APP_HOST);

const NotificationsPatinador = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const notificationsRef = useRef(notifications);
  const soundRef = useRef(null);

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    soundRef.current = new Howl({ src: [alertSound] }); // Create Howl instance
    soundRef.edit = new Howl({ src: editSound });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const cachedUser = localStorage.getItem("user");

    if (cachedUser) {
      setUser(JSON.parse(cachedUser));
      setLoading(false);
    }

    if (token) {
      fetch(`${process.env.REACT_APP_HOST}/api/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Unauthorized");
          }
          return response.json();
        })
        .then((data) => {
          setUser(data);
          localStorage.setItem("user", JSON.stringify(data));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    fetch(`${process.env.REACT_APP_HOST}/notifications`)
      .then((response) => response.json())
      .then((data) => {
        const filteredNotifications = data.filter(
          (notification) =>
            !notification.notification.includes(
              "ha editado un toma pedido con ID"
            )
        );
        setNotifications(filteredNotifications);
        notificationsRef.current = filteredNotifications;
      })
      .catch((error) => {
        console.error("Error fetching notifications: ", error);
      });

    // Listen for real-time notifications
    socket.on("notification added", (newNotification) => {
      console.log("Received new notification from server:", newNotification);
      if (soundRef.current) {
        soundRef.current.play(); // Play the sound
      }
      if (Array.isArray(newNotification)) {
        newNotification.forEach((notification) => {
          if (validateNotificationStructure(notification)) {
            setNotifications((prevNotifications) => [
              notification,
              ...prevNotifications,
            ]);
          } else {
            console.error("Invalid notification structure", notification);
          }
        });
      } else if (validateNotificationStructure(newNotification)) {
        setNotifications((prevNotifications) => [
          newNotification,
          ...prevNotifications,
        ]);
      } else {
        console.error("Invalid notification structure", newNotification);
      }
    });

    socket.on("notification finalizado", (newNotification) => {
      console.log(
        "Received new finalizado notification from server:",
        newNotification
      );
      if (soundRef.edit) {
        soundRef.edit.play(); // Play the sound
      }
      if (Array.isArray(newNotification)) {
        newNotification.forEach((notification) => {
          if (validateNotificationStructure(notification)) {
            setNotifications((prevNotifications) => [
              notification,
              ...prevNotifications,
            ]);
          } else {
            console.error("Invalid notification structure", notification);
          }
        });
      } else if (validateNotificationStructure(newNotification)) {
        setNotifications((prevNotifications) => [
          newNotification,
          ...prevNotifications,
        ]);
      } else {
        console.error("Invalid notification structure", newNotification);
      }
    });

    socket.on("notifications deleted", (deletedNotifications) => {
      console.log(
        "Received deleted notifications from server:",
        deletedNotifications
      );
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) =>
            !deletedNotifications.deletedIds.includes(notification.id)
        )
      );
    });

    // Clean up the effect
    return () => {
      socket.off("notification added");
      socket.off("notification finalizado");
      socket.off("notifications deleted");
    };
  }, []);

  const validateNotificationStructure = (notification) => {
    const requiredFields = [
      "id",
      "user_id",
      "full_name",
      "notification",
      "created_at",
    ];
    for (const field of requiredFields) {
      if (
        !notification.hasOwnProperty(field) ||
        notification[field] === undefined ||
        notification[field] === null
      ) {
        console.error(
          `Notification is missing the field: ${field}`,
          notification
        );
        return false;
      }
    }
    return true;
  };

  if (loading) {
    return (
      <div className="loading">
        <p>Cargando...</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="error">
        <p>Error al cargar los datos del usuario.</p>
      </div>
    );
  }

  return (
    <div className="Notifications">
      <div className="user">
        <p>{user.full_name}</p>
        <span>{user.role_name}</span>
      </div>
      <div className="title mb-2">Notificaciones</div>
      <UpdatesPatinador notifications={notifications} />
    </div>
  );
};

export default NotificationsPatinador;
