// Sidebar imports
import {
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilCommentLines,
    UilHistory,
    UilUsdSquare,
    UilMoneyWithdrawal,
    UilSignout,
} from '@iconscout/react-unicons';

// Sidebar Data
export const SidebarDataAdmin = [
    {
        icon: UilEstate,
        heading: 'Dashboard',
        link: '/admin/dashboard'
    },
    {
        icon: UilUsersAlt,
        heading: 'Usuarios',
        link: '/admin/users'
    },
    {
        icon: UilCommentLines,
        heading: 'Chat',
        link: '/admin/chat'
    },
    {
        icon: UilHistory,
        heading: 'Historial',
        link: '/admin/history'
    },
    {
        icon: UilSignout,
        heading: 'Salir',
        link: '/login'
    }
];

export const CardsData = [

    {
        title: "Finalizados",
        color: {
            backGround: "linear-gradient(180deg, #232526 0%, #414345 100%)",
            boxShadow: "0px 10px 20px 0px #1f2123",
        },
        barValue: 70,
        value: "25,970",
        png: UilUsdSquare,
        series: [
            {
                name: "Finalizados",
                data: [31, 40, 28, 51, 42, 109, 100]
            },
        ],
    },
    {
        title: "Pendientes",
        color: {
            backGround: "linear-gradient(180deg, #232526 0%, #414345 100%)",
            boxShadow: "0px 10px 20px 0px #1f2123",
        },
        barValue: 80,
        value: "14,270",
        png: UilMoneyWithdrawal,
        series: [
            {
                name: "Pendientes",
                data: [10, 100, 50, 70, 80, 30, 40],
            },
        ],
    },
    {
        title: "Rendimiento",
        color: {
            backGround: "linear-gradient(180deg, #232526 0%, #414345 100%)",
            boxShadow: "0px 10px 20px 0px #1f2123",
        },
        barValue: 60,
        value: "4,270",
        png: UilClipboardAlt,
        series: [
            {
                name: "Rendimiento",
                data: [10, 25, 15, 30, 12, 15, 20],
            },
        ],
    },

]

// Recent Updates Card Data
export const UpdatesData = [

    {
        name: 'Sebastian Puerta',
        notification: ' a creado un toma pedido con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Santiago Gonzalez',
        notification: ' a confirmado un toma pedido con ID #12145',
        time: 'hace 10 minutos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
    {
        name: 'Valentina Martinez',
        notification: ' a finalizado el chat con ID #12145',
        time: 'hace 20 segundos',
    },
]