import React, { useState, useRef } from "react";
import "./SubirTP.css";
import cloudImage from "../../../../assets/img/cloud-computing.png";
import axios from "axios";

const SubirTP = ({ userId }) => {
  console.log("Received user ID in SubirTP:", userId);

  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const fileInputRef = useRef(null);

  function selectFiles() {
    fileInputRef.current.click();
  }

  function onFileSelect(event) {
    const files = event.target.files;
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            file: files[i],
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  }

  function deleteImage(index) {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  }

  function onDragOver(event) {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  }

  function onDragLeave(event) {
    event.preventDefault();
    setIsDragging(false);
  }

  function onDrop(event) {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            file: files[i],
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  }

  async function uploadImages() {
    setIsLoading(true);
    setSuccessMessage("");
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image.file);
    });
    console.log("User ID:", userId);
    formData.append("user_id", userId);

    const newImageLoadingStatus = {};
    images.forEach((image, index) => {
      newImageLoadingStatus[index] = true;
    });
    setImageLoadingStatus(newImageLoadingStatus);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Images uploaded successfully:", response.data);
        setSuccessMessage("Imagenes subidas correctamente");
        setTimeout(() => setSuccessMessage(""), 5000);
      } else {
        console.error("Failed to upload images:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setIsLoading(false);
      setImages([]);
      const newImageLoadingStatus = {};
      images.forEach((image, index) => {
        newImageLoadingStatus[index] = false;
      });
      setImageLoadingStatus(newImageLoadingStatus);
    }
  }

  function openModal(image) {
    setCurrentImage(image);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setCurrentImage(null);
  }

  return (
    <div className="subir-container">
      <p
        className="text-white font-bold mb-6 text-2xl"
        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        Subir Toma Pedido
      </p>
      <div className="card">
        <div
          className="drag-area"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          {isDragging ? (
            <span className="select">Suelte la imagen</span>
          ) : (
            <>
              <img src={cloudImage} alt="Upload" className="cloud-image" />
              Arrastre y suelte la imagen aquí o
              <span className="select" role="button" onClick={selectFiles}>
                Busque
              </span>
            </>
          )}
          <input
            name="file"
            type="file"
            className="file"
            multiple
            ref={fileInputRef}
            onChange={onFileSelect}
          />
        </div>
        <div className="container">
          {images.map((image, index) => (
            <div className="image" key={index}>
              <span className="delete" onClick={() => deleteImage(index)}>
                &times;
              </span>
              <img
                src={image.url}
                alt={image.name}
                onClick={() => openModal(image)}
                style={{
                  opacity: imageLoadingStatus[index] ? 0.5 : 1,
                  position: "relative",
                }}
              />
              {imageLoadingStatus[index] && (
                <div className="loading-spinner"></div>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={uploadImages}
          disabled={isLoading || images.length === 0}
          className={`upload-button ${isLoading ? "loading-button" : ""} ${
            images.length === 0 ? "disabled-button" : ""
          }`}
        >
          {isLoading ? "Cargando..." : "Subir"}
        </button>

        {successMessage && <p className="success-message">{successMessage}</p>}

        {isModalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <img
                src={currentImage.url}
                alt={currentImage.name}
                className="modal-image"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubirTP;
