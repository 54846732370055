import React from "react";
import HistoryAdminTable from "./HistoryTableAdmin/HistoryTableAdmin";

const HistoryAdmin = ({ userId }) => {
  return (
    <div className="justify-center flex flex-col items-center mt-16">
      <div
        className="text-white text-center font-bold text-2xl mb-3"
        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        Historial de Toma Pedidos
      </div>

      <HistoryAdminTable userId={userId} />
    </div>
  );
};

export default HistoryAdmin;
