import React from "react";
import "./ModalCanvas.css";
import KonvaCanvas from "../FabricCanvas/FabricCanvas";

const ModalCanvas = ({ open, onClose, imageUrl, orderId }) => {
  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-canvas">
        {imageUrl && (
          <KonvaCanvas
            imageUrl={imageUrl}
            orderId={orderId}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default ModalCanvas;
