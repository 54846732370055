import React from "react";
import Modal from 'react-modal';
import DeletionTimeNotiSetting from "../DeletionTimeNotiSetting/DeletionTimeNotiSetting";

Modal.setAppElement('#root');

const DeletionTimeModal = ({ isOpen, onRequestClose }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Deletion Time Settings"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '20px',
                    maxWidth: '500px',
                    width: '90%',
                    padding: '20px',
                    border: 'none',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
                    justifyContent: 'center',
                    textAlign: 'center',
                }
            }}
        >
            <div className="mb-4">
                <DeletionTimeNotiSetting />
            </div>
            <button className="modal-close-button" onClick={onRequestClose}>Cerrar</button>
        </Modal>
    )

}

export default DeletionTimeModal;
