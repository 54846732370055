import React, { useEffect, useState } from "react";
import axios from "axios";

const DeletionTimeNotiSetting = () => {
  const [deletionTime, setDeletionTime] = useState(60);
  const [currentDeletionTime, setCurrentDeletionTime] = useState(60);

  useEffect(() => {
    const fetchDeletionTime = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST}/api/deletion-time-notifications`
        );
        const deletionTimeInHours = response.data.deletionTime / 60;
        setDeletionTime(deletionTimeInHours);
        setCurrentDeletionTime(deletionTimeInHours);
      } catch (error) {
        console.error("Error fetching deletion time:", error);
      }
    };

    fetchDeletionTime();
  }, []);

  const handleTimeChange = (event) => {
    const newDeletionTime = Number(event.target.value);
    if (newDeletionTime >= 1 && newDeletionTime <= 2400) {
      setDeletionTime(newDeletionTime);
    } else {
      alert(
        "El tiempo de borrado debe estar entre 1 y 2400 (48 horas) minutos."
      );
    }
  };

  const handleSubmit = () => {
    if (deletionTime >= 1 && deletionTime <= 2400) {
      axios
        .post(`${process.env.REACT_APP_HOST}/api/deletion-time-notifications`, {
          deletionTimeNotifications: deletionTime * 60, // Convert minutes to seconds
        })
        .then((response) => {
          //alert(response.data.message);
          setCurrentDeletionTime(deletionTime);
        })
        .catch((error) => {
          console.error("Error updating deletion time:", error);
        });
    } else {
      alert(
        "El tiempo de borrado debe estar entre 1 y 2400 (48 horas) minutos."
      );
    }
  };

  return (
    <div>
      <p
        className="font-bold text-white"
        style={{
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
          fontSize: "18px",
          color: "#444",
        }}
      >
        Establecer el tiempo de{" "}
        <span className="text-red-500" style={{ fontSize: "18px" }}>
          borrar
        </span>{" "}
        las notificaciones.
      </p>
      <input
        className="w-1/4 p-2 m-2 border border-gray-300 rounded"
        type="number"
        value={deletionTime}
        onChange={handleTimeChange}
        min="1"
        step="1"
      />
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleSubmit}
      >
        Actualizar
      </button>
      <p
        className="text-white font-semibold"
        style={{ fontSize: "13px", color: "#444" }}
      >
        Mínimo 1 minuto y máximo 2400 (48 horas) minutos
      </p>
      <p
        className="text-white font-semibold"
        style={{ fontSize: "13px", color: "#444" }}
      >
        Tiempo actual: {currentDeletionTime} minutos
      </p>
    </div>
  );
};

export default DeletionTimeNotiSetting;
