import React from "react";
import { Link } from "react-router-dom";
import './NotFound.css'; // Ensure the correct path to your CSS file

function NotFound() {
    return (
        <div className="App">
            <div className="glass-card">
                <h1>404 - No Encontrado</h1>
                <p>Lo siento, la página que buscas no se pudo encontrar o no tienes permisos suficientes para acceder a esta ruta.</p>
                <Link to="/">Volver al inicio</Link>
            </div>
        </div>
    );
}

export default NotFound;
