import axios from "axios";
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useParams, useNavigate } from "react-router-dom";
import theme from "../../../../../styles/theme";
import Modal from "../../../../Utils/Modal/Modal";
import "./OrderDetailAsesor.css";

const OrderDetailAsesor = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const makeStyles = (order_status) => {
    if (order_status === "Finalizado") {
      return {
        color: "green",
        background: "rgb(145 254 159 / 47%)",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    } else if (order_status === "Pendiente") {
      return {
        color: "red",
        background: "rgb(254 145 159 / 47%)",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    } else if (order_status === "Revisando") {
      return {
        color: "blue",
        background: "#adc6ff94",
        borderRadius: "20px",
        padding: "5px 10px",
      };
    }
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    return `${date.toLocaleDateString(
      "es-ES",
      options
    )}, ${date.toLocaleTimeString("es-ES", timeOptions)}`;
  };

  const openModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageUrl("");
  };

  const finishOrderTaking = (event) => {
    event.preventDefault();
    setLoading(true);

    const confirmation_date = new Date().toISOString();

    const userId = localStorage.getItem("user_id");
    const userFullName = localStorage.getItem("full_name");

    axios
      .put(`${process.env.REACT_APP_HOST}/api/orders-taking/${orderId}`, {
        order_status: "Finalizado",
        confirmation_date,
        user_id: userId,
        user_full_name: userFullName,
      })
      .then((response) => {
        setOrder((prevOrder) => ({
          ...prevOrder,
          order_status: "Finalizado",
          confirmation_date: formatDateTime(confirmation_date),
        }));
        console.log("Toma pedido actualizado: ", response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_HOST}`);

    socket.on("order status updated", (updatedOrder) => {
      console.log("Evento recibido: order status updated", updatedOrder);
      if (updatedOrder.id === parseInt(orderId, 10)) {
        console.log("Updated order data: ", updatedOrder);
        const updatedOrderWithCorrectURL = {
          ...updatedOrder,
          file_url: updatedOrder.file_url,
          confirmation_date: updatedOrder.confirmation_date
            ? formatDateTime(updatedOrder.confirmation_date)
            : null,
        };
        console.log(
          "Constructed file_url from socket: ",
          updatedOrderWithCorrectURL.file_url
        );

        setOrder(updatedOrderWithCorrectURL);
      }
    });

    socket.on("order-image-updated", (updatedOrder) => {
      window.location.reload();
    });

    return () => socket.disconnect();
  }, [orderId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST}/orders/${orderId}`)
      .then((response) => {
        console.log("Order: ", response.data);
        const orderWithCorrectURL = {
          ...response.data,
          file_url: response.data.file_url,
          confirmation_date: response.data.confirmation_date
            ? formatDateTime(response.data.confirmation_date)
            : null,
        };
        console.log("Constructed file_url: ", orderWithCorrectURL.file_url);
        setOrder(orderWithCorrectURL);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [orderId]);

  if (!order) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container-order-detail-asesor text-white items-center justify-center flex flex-col p-8">
      <div>
        <button onClick={() => navigate(-1)} className="back-button">
          Volver
        </button>
        <p className="text-2xl mb-4 font-bold">
          Estado:{" "}
          <span
            style={makeStyles(order.order_status)}
            className="font-semibold"
          >
            {order.order_status}
          </span>
        </p>
        <p className="text-2xl mb-4 font-bold">
          Confirmación:{" "}
          <span className="font-semibold">
            {order.confirmation_date || "N/A"}
          </span>
        </p>
        <img
          className="rounded-xl"
          src={`${process.env.REACT_APP_HOST}/${order.file_url}`}
          alt="Toma Pedido"
          style={{ width: "600px", height: "320px" }}
        />

        <div className="mt-4 flex flex-row">
          <button
            onClick={() =>
              openModal(`${process.env.REACT_APP_HOST}/${order.file_url}`)
            }
            className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#3a89b7] focus:ring-opacity-50"
            style={{
              background: "#3a98b7",
              backgroundColor: "#3a98b7",
            }}
          >
            Abrir Toma Pedido
          </button>
          <div className="p-4"></div>
          <button
            onClick={finishOrderTaking}
            className="w-full mt-2 py-3 text-white font-bold rounded focus:outline-none focus:ring-2 focus:ring-[#7DB73A] focus:ring-opacity-50"
            style={{
              background: "#7DB73A",
              backgroundColor: theme.colors.secondary,
            }}
          >
            {loading ? "Cargando..." : "Finalizar Toma Pedido"}
          </button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        imageUrl={currentImageUrl}
      />
    </div>
  );
};

export default OrderDetailAsesor;
