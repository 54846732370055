import React, { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableAsesor.css";
import chatIcon from "../../../../../assets/img/chat.png";
import Modal from "../../../../Utils/Modal/Modal";

const socket = io(`${process.env.REACT_APP_HOST}`);

const TableAsesor = ({ userId }) => {
  console.log("Received user ID in TableAsesor:", userId);

  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST}/images`
        );
        console.log("Fetched data:", response.data);

        const filteredRows = response.data
          .filter(
            (row) =>
              (row.order_status === "Revisando" ||
                row.order_status === "Pendiente") &&
              Number(row.user_id) === Number(userId)
          )
          .map((row) => {
            const fileUrl = `${process.env.REACT_APP_HOST}/${row.file_url}`;
            console.log("File URL MisTP - TableAsesor:", fileUrl);
            return {
              ...row,
              file_url: fileUrl,
            };
          });
        setRows(filteredRows);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();

    socket.on("image uploaded", (newImages) => {
      const filteredRows = newImages
        .filter(
          (image) =>
            (image.order_status === "Revisando" ||
              image.order_status === "Pendiente") &&
            Number(image.user_id) === Number(userId)
        )
        .map((image) => ({
          ...image,
          file_url: `${process.env.REACT_APP_HOST}/${image.file_url}`,
        }));
      setRows((prevRows) => [...prevRows, ...filteredRows]);
    });

    return () => {
      socket.off("image uploaded");
    };
  }, [userId]);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_HOST}`);

    socket.on("order status updated", (updatedOrder) => {
      if (
        (updatedOrder.order_status === "Revisando" ||
          updatedOrder.order_status === "Pendiente") &&
        Number(updatedOrder.user_id) === Number(userId)
      ) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === updatedOrder.id
              ? {
                  ...updatedOrder,
                  file_url: `${process.env.REACT_APP_HOST}/${updatedOrder.file_url}`,
                }
              : row
          )
        );
      } else {
        setRows((prevRows) =>
          prevRows.filter((row) => row.id !== updatedOrder.id)
        );
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [userId]);

  const makeStyles = (order_status) => {
    if (order_status === "Finalizado") {
      return {
        background: "rgb(145 254 159 / 47%)",
        color: "green",
        padding: "7px 10px",
      };
    } else if (order_status === "Pendiente") {
      return {
        color: "red",
        background: "rgb(254 145 159 / 47%)",
        borderRadius: "20px",
        padding: "7px 10px",
      };
    } else if (order_status === "Revisando") {
      return {
        color: "blue",
        background: "#adc6ff94",
        borderRadius: "20px",
        padding: "7px 10px",
      };
    }
  };

  const openModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageUrl("");
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    return `${date.toLocaleDateString(
      "es-ES",
      options
    )}, ${date.toLocaleTimeString("es-ES", timeOptions)}`;
  };

  const openOrderDetail = (orderId) => {
    navigate(`/asesor/mis-tp/order/${orderId}`);
  };

  return (
    <div className="Table">
      {rows.length === 0 ? (
        <div className="align-center text-[#ff0000] font-bold text-2xl">
          No hay toma pedidos
        </div>
      ) : (
        <TableContainer
          component={Paper}
          className="table-container"
          style={{
            boxShadow: "0px 13px 20px 0px #80808029",
            maxHeight: "calc(100vh - 180px)",
          }}
        >
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Toma Pedido</TableCell>
                <TableCell align="center">ID del Toma Pedido</TableCell>
                <TableCell align="center">Fecha De Creación</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    <img
                      src={row.file_url}
                      alt="Preview"
                      style={{
                        width: "100px",
                        height: "65px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => openModal(row.file_url)}
                    />
                  </TableCell>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {formatDateTime(row.created_at)}
                  </TableCell>
                  <TableCell align="center">
                    <span
                      className="status"
                      style={makeStyles(row.order_status)}
                    >
                      {row.order_status}
                    </span>
                  </TableCell>
                  <TableCell align="center" className="Details">
                    <button
                      className=""
                      onClick={() => openOrderDetail(row.id)}
                    >
                      <img
                        src={chatIcon}
                        alt="Chat"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        imageUrl={currentImageUrl}
      />
    </div>
  );
};

export default TableAsesor;
