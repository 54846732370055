import React from "react";
import "./HistoryAsesor.css";
import HistoryTableAsesor from "./HistoryTableAsesor/HistoryTableAsesor";

const HistoryAsesor = ({ userId }) => {
  return (
    <div className="justify-center flex flex-col items-center mt-16">
      <div
        className="text-white text-center font-bold text-2xl mb-3"
        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        Historial de Toma Pedidos
      </div>

      <HistoryTableAsesor userId={userId} />
    </div>
  );
};

export default HistoryAsesor;
