import React from "react";
import { Navigate } from "react-router-dom";

const withAuth = (WrappedComponent, allowedRoles) => {

    return (props) => {
        const userRole = localStorage.getItem('user_role');
        console.log(`withAuth: userRole is ${userRole}`);

        if (!userRole) {
            console.log('withAuth: No userRole found, redirecting to /');
            return <Navigate to='/' />;
        }

        const parsedUserRole = parseInt(userRole, 10);
        console.log(`withAuth: parsedUserRole is ${parsedUserRole}`);

        if (!allowedRoles.includes(parsedUserRole)) {
            console.log(`withAuth: User role ${parsedUserRole} not allowed, redirecting to appropriate route`);
            return <Navigate to={`/${parsedUserRole === 1 ? 'admin' : parsedUserRole === 2 ? 'asesor' : 'patinador'}`} />;
        }

        console.log('withAuth: User role allowed, rendering component');
        return <WrappedComponent {...props} />;
    };

};

export default withAuth;
