import React from "react";
import "./MisTP.css";
import TableAsesor from "./Table/TableAsesor";

const MisTP = ({ userId }) => {
  console.log("Received user ID in MisTP:", userId);

  return (
    <div className="justify-center flex flex-col items-center mt-16">
      <div
        className="text-white text-center font-bold text-2xl mb-8"
        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        Mis Toma Pedidos
      </div>
      <TableAsesor userId={userId} />
    </div>
  );
};

export default MisTP;
